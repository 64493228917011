const searchInput = document.getElementById('searchInput');
const searchContainer = document.querySelector('.search-container');
const searchIcon = document.getElementById('searchIcon');

let isSearchActive = false;

if (searchInput && searchContainer && searchIcon) {
    searchInput.addEventListener('keyup', function () {
        if (this.value.length > 0) {
            searchContainer.classList.add('active');
            isSearchActive = true;
        }
    });

    searchIcon.addEventListener('click', function () {
        searchContainer.classList.add('active');
        searchInput.focus();
        isSearchActive = true;
    });

    searchInput.addEventListener('focus', function () {
        searchContainer.classList.add('active');
        isSearchActive = true;
    });

    document.addEventListener('click', function (e) {
        if (!searchContainer.contains(e.target)) {
            if (searchInput.value.length === 0) {
                searchContainer.classList.remove('active');
                isSearchActive = false;
            }
        }
    });
} else {
    console.error("One or more elements are missing: searchInput, searchContainer, or searchIcon.");
}
