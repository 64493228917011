export function setupNavigation(selector = '.navigation') {
    const navigation = document.querySelector(selector);

    if (navigation) {
        const navigationBurgerBtn = navigation.querySelector('.navigation__menu-icon');
        if (navigationBurgerBtn) {
            navigationBurgerBtn.addEventListener('click', () => {
                document.body.classList.toggle('js-navigation-open');
            });
        }

        const navigationItemsWithSub = navigation.querySelectorAll('.navigation__item--has-sub');

        Array.from(navigationItemsWithSub).forEach(item => {
            const triggerLink = item.querySelector('.navigation__link');
            const languageSelector = item.querySelector('.navigation__lang-wrap');

            if (languageSelector) {
                triggerLink.addEventListener('click', e => {
                    e.preventDefault();
                    item.classList.toggle('js-closed');
                });
            }
        });

        document.addEventListener('click', function (event) {
            navigationItemsWithSub.forEach(item => {
                const languageSelector = item.querySelector('.navigation__lang-wrap');
                const triggerLink = item.querySelector('.navigation__link');

                if (!item.contains(event.target) && event.target !== triggerLink) {
                    item.classList.add('js-closed');
                }
            });
        });
    }
}

function openMenu(item) {
    item.classList.remove('js-closed');
}

function closeMenu(item) {
    item.classList.add('js-closed');
}
document.addEventListener('DOMContentLoaded', function () {
    // Language button
    const languageButton = document.querySelector('.language__link'); // Adjust this selector if needed

    if (languageButton) {
        languageButton.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            const langWrap = document.querySelector('.navigation__lang-wrap'); // Adjust selector if needed

            if (langWrap) {
                // Toggle the visibility
                langWrap.classList.toggle('js-closed');
            }

            // If you want to close the search module (if it exists)
            const searchContainer = document.getElementById('searchContainer');
            if (searchContainer) {
                searchContainer.classList.add('js-closed'); // Assuming you want to close it
            }
        });
    }
});
