import { scrollTop } from '../utils/scroll';
import { isIE11 } from '../utils/helpers';

export function setupIntersect() {
    const targets = document.querySelectorAll('[data-intersect]');

    if (isIE11) {
        Array.from(targets).forEach(element => {
            element.classList.add('in-viewport');
        });
    }
    else {
        if(targets && targets.length > 0) {
            Array.from(targets).forEach(watchForIntersect);
        }
    }
}

function watchForIntersect(target) {
    const options = {
        threshold: 0.1
    }

    const io = new IntersectionObserver((entries, observer) => {
        Array.from(entries).forEach(entry => {
            if(entry.isIntersecting) {
                entry.target.classList.add('in-viewport');
            }
        })
    }, options);

    io.observe(target);
}