/**
 * Default element selector is data-action*="process-line"
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class ProcessLineItem {
  /**
   * The constructor is fired once the class is instantiated.
   *
   * @param {HTMLElement} description
   */

  constructor(index, item, header, triangle, length) {
    this.dom = {
        item,
        itemContainer: item.parentNode,
        header: header,
        triangle: triangle,
        description: document.getElementById('process_line-' + index),
        descripionContent: document.getElementById('process_line__description__content-' + index),
        modal: document.getElementById("process_line__modal-" + index),
        modalClose: document.getElementById("process_line__modal__close-" + index)
    };

    /* Set header items to the same width as process line items to align item headers and items */
    let itemContainerWidth = this.dom.itemContainer.offsetWidth;
    this.dom.header.style.width = (itemContainerWidth).toString() + 'px';

    window.addEventListener('resize', e => {
      let itemContainerWidth = this.dom.itemContainer.offsetWidth;
      this.dom.header.style.width = (itemContainerWidth).toString() + 'px';
    })

    window.innerWidth > 760 ? descriptionScreenView(index, this.dom, length) : descriptionMobileView(this.dom);
  }
}

export function setupProcessLine(
  selectorItem = '[data-action="process-line-item"]',
  selectorTriangle = '[data-action="process-line-triangle"]',
  selectorHeader = '[data-action="process-line-header"]') {
  const processLineItems = document.querySelectorAll(selectorItem);
  const processLineTriangle = document.querySelectorAll(selectorTriangle);
  const processLineHeader = document.querySelectorAll(selectorHeader);

  if (processLineItems) {
    for (let i = 0; i < processLineItems.length; i++) {
      void new ProcessLineItem(
        i + 1,
        processLineItems[i],
        processLineHeader[i],
        processLineTriangle[i],
        processLineItems.length
      );
    }
  }
}

export function descriptionScreenView(index, dom, length) {
  if (index == 1) {
    dom.description.style.left = '100px';
  }
  else if (index > Math.round(length / 2)) {
    dom.description.style.left = index == length ? '-500px' : '-350px';
  }

  dom.item.addEventListener('click', e => {
    if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
      e.preventDefault();
    }

    if (dom.description.classList.contains('process_line__description__hidden')) {
      hideDescriptions();
      dom.description.classList.remove('process_line__description__hidden');
      dom.triangle.classList.remove('process_line__description__triangle__hidden');
      dom.item.classList.add('process_line__item-clicked');

      if (index % 2 == 0) {
        setDescriptionPosition(index);
      }
    }
    else {
      dom.description.classList.add('process_line__description__hidden');
      dom.triangle.classList.add('process_line__description__triangle__hidden');
      dom.item.classList.remove('process_line__item-clicked');
    }
  });
}

export function descriptionMobileView(dom) {
  dom.item.addEventListener('click', e => {
    if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
      e.preventDefault();
    }

    dom.modal.style.display = "block";
    document.body.style.overflow = "hidden";
  });

  dom.modal.addEventListener('click', event => {
    if (event.target == dom.modal) {
      dom.modal.style.display = "none";
      document.body.style.overflow = "auto";
    }
  });

  dom.modalClose.addEventListener('click', e => {
    if (e && !(e.ctrlKey || e.metaKey || e.shiftKey)) {
      e.preventDefault();
    }

    dom.modal.style.display = "none";
    document.body.style.overflow = "auto";
  });
}

export function getDescriptionContentHeight(index, selectorDescription = '[data-action="process-line-description-content"]') {
  const processLineDescriptionContent = document.querySelectorAll(selectorDescription);

  return processLineDescriptionContent[index].offsetHeight;
}

export function hideDescriptions(
  selectorItem = '[data-action="process-line-item"]',
  selectorDescription = '[data-action="process-line-description"]',
  selectorTriangle = '[data-action="process-line-triangle"]') {
  const processLineItem = document.querySelectorAll(selectorItem);
  const processLineDescriptions = document.querySelectorAll(selectorDescription);
  const processLineTriangle = document.querySelectorAll(selectorTriangle)

  // Closes all process line descriptions
  if (processLineDescriptions) {
    for (let i = 0; i < processLineDescriptions.length; i++) {
      if (processLineItem[i].classList.contains('process_line__item-clicked')) {
        processLineItem[i].classList.remove('process_line__item-clicked');
      }
      if (!processLineDescriptions[i].classList.contains('process_line__description__hidden')) {
        processLineDescriptions[i].classList.add('process_line__description__hidden');
      }
      if (!processLineTriangle[i].classList.contains('process_line__description__triangle__hidden')) {
        processLineTriangle[i].classList.add('process_line__description__triangle__hidden');
      }
    }
  }
}

export function setDescriptionPosition(index) {
  const descriptionContent = document.getElementById('process_line__description__content-' + index);

  descriptionContent.style.top = (-(descriptionContent.offsetHeight + 95)).toString() + 'px';
}
