import SwiperCore, { Pagination, Parallax } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Pagination, Parallax]);

export function setupSwipers(selector = '.swiper-container') {
    const swiperContainers = document.querySelectorAll(selector);

    Array.from(swiperContainers).forEach(swiperContainer => {
        const swiperImages = swiperContainer.querySelectorAll('img');
        let loadedImages = 0;

        function checkSliderImages() {
            loadedImages++;

            if (loadedImages == swiperImages.length) {
                swiperContainer.classList.add('swiper-images-loaded');

                new SwiperCore(swiperContainer, {
                    pagination: {
                        el: ".swiper-pagination",
                        type: "progressbar"
                    },
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    centeredSlidesBounds: true,
                    speed: 500,
                    parallax: true
                });
            }
        }

        Array.from(swiperImages).forEach(image => {
            if (image.complete) {
                checkSliderImages();
            } else {
                image.addEventListener('load', checkSliderImages);
                image.addEventListener('error', checkSliderImages);
            }
        });
    });
};